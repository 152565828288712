import { getData } from "./data";
import { getStoryById } from "./stories";
import { useEffect, useState, createContext } from "react";

const data = getData();

/************************************************************
 * FUNCTIONS WITH NO STATE
 * Functions that don't access React state are found below.
 ************************************************************/

const getPlayerConfiguration = (players) => {
  const configurations = data.tabletopConfigurations;
  return configurations.find((config) =>
    players.sort().join(",") === config.playersSetting.sort().join(",")
      ? config.tilesToBeRemoved
      : null
  );
};

const getTileIdsToRemove = (arr) => {
  if (arr) {
    const tileIds = [];
    arr.map((storyId) => tileIds.push(getStoryById(storyId._ref).tileId));
    return removeDuplicates(tileIds);
  } else {
    return [];
  }
};

const removeDuplicates = (arr) => {
  if (arr) {
    return arr
      .filter((item, index) => arr.indexOf(item) === index)
      .sort((a, b) => a - b);
  } else {
    return [];
  }
};

const getRandomItem = (arr) => {
  // get random index value
  const randomIndex = Math.floor(Math.random() * arr.length);

  // get random item
  const item = arr[randomIndex];

  return item;
};

/************************************************************
 * REACT STATE
 * This module's React state is kept inside the context
 * defined below.
 ************************************************************/

/*
 * Custom context that holds the state for players
 */
const PlayersContext = createContext({});

const PlayersContextProvider = ({ children }) => {
  const [players, _setPlayers] = useState([]);
  const [firstPlayer, _setFirstPlayer] = useState(null);
  const [currentPlayer, _setCurrentPlayer] = useState(null);

  // Recover saved values on start
  useEffect(() => {
    try {
      const savedPlayers = JSON.parse(window.localStorage.getItem("players"));
      _setPlayers(Array.isArray(savedPlayers) ? savedPlayers : []);

      const savedFirstPlayer = window.localStorage.getItem("firstPlayer");
      _setFirstPlayer(savedFirstPlayer);

      const savedCurrentPlayer = window.localStorage.getItem("currentPlayer");
      _setCurrentPlayer(savedCurrentPlayer);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const setPlayers = (newPlayers) => {
    // Update state
    _setPlayers(newPlayers);

    // Also update localStorage
    try {
      window.localStorage.setItem("players", JSON.stringify(newPlayers));
    } catch (error) {
      console.log(error);
    }
  };

  const setNewPlayers = (players, firstPlayer) => {
    const playersWithoutFirtsPlayer = players.filter((p) => p !== firstPlayer);
    const newPlayers = [firstPlayer, ...playersWithoutFirtsPlayer];

    // Update state
    _setPlayers(newPlayers);

    // Also update localStorage
    try {
      window.localStorage.setItem("players", JSON.stringify(newPlayers));
    } catch (error) {
      console.log(error);
    }
  };

  const reorderPlayers = (players, firstPlayer) => {
    const reorderedPlayers = players.filter((p) => p !== firstPlayer);
    console.log(reorderedPlayers, "prima volta");
    reorderedPlayers.push(firstPlayer);
    setFirstPlayer(reorderedPlayers[0]);
    setCurrentPlayer(reorderedPlayers[0]);

    console.log(reorderedPlayers);

    // Update state
    _setPlayers(reorderedPlayers);

    // Also update localStorage
    try {
      window.localStorage.setItem("players", JSON.stringify(reorderedPlayers));
    } catch (error) {
      console.log(error);
    }
  };

  const setFirstPlayer = (newFirstPlayer) => {
    // Update state
    _setFirstPlayer(newFirstPlayer);

    try {
      window.localStorage.setItem("firstPlayer", newFirstPlayer);
    } catch (error) {
      console.log(error);
    }
  };

  const setCurrentPlayer = (newCurrentPlayer) => {
    // Update state
    _setCurrentPlayer(newCurrentPlayer);

    try {
      window.localStorage.setItem("currentPlayer", newCurrentPlayer);
    } catch (error) {
      console.log(error);
    }
  };

  const getIndexPlayer = (player) => {
    return players.findIndex((p) => p === player);
  };

  const getPlayerNumber = () => {
    return players.length;
  };

  const clearPlayers = () => {
    setPlayers([]);
    setFirstPlayer(null);
    setCurrentPlayer(null);
  };

  const context = {
    firstPlayer,
    currentPlayer,
    players,
    setPlayers,
    reorderPlayers,
    setNewPlayers,
    setFirstPlayer,
    setCurrentPlayer,
    getIndexPlayer,
    clearPlayers,
    getPlayerNumber,
  };

  return (
    // the Provider gives access to the context to its children
    <PlayersContext.Provider value={context}>
      {children}
    </PlayersContext.Provider>
  );
};

export {
  getPlayerConfiguration,
  getTileIdsToRemove,
  removeDuplicates,
  getRandomItem,
  PlayersContext,
  PlayersContextProvider,
};
