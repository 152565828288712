import { IonButton, IonCard, IonCardContent } from "@ionic/react";
import "./DragonCards.css";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useContext } from "react";
import { PlayersContext } from "../logic/players";

const Tabletop = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { getPlayerNumber } = useContext(PlayersContext);

  // Recupero del numero di giocatori
  const playersNumber = getPlayerNumber();
  console.log(`Numero di giocatori: ${playersNumber}`);

  /**
   * Funzione per ottenere l'immagine corretta per un dato step e numero di giocatori.
   * @param {number} step - Lo step corrente (0, 1, 2, 3).
   * @returns {string} - Percorso dell'immagine.
   */
  const getImagePath = (step) => {
    if (playersNumber === 2) {
      return `assets/img/table-configuration/2_players/${step}.png`;
    } else if (playersNumber === 3) {
      return `assets/img/table-configuration/3_players/${step}.png`;
    } else if (playersNumber === 4) {
      return `assets/img/table-configuration/4_players/${step}.png`;
    } else {
      return ""; // Fallback per numero di giocatori non valido
    }
  };

  return (
    <div>
      <IonCard className="ion-padding small-content-with-background">
        <h2 className="ion-text-center">{t("table-configuration")}</h2>
        <IonCardContent>
          <div>
            <div className="ion-margin-top">
              <h3>Realizzazione del tabellone</h3>
              <p>
                Il tabellone di Age of Dragons viene completato seguendo 4 step.
                Ogni step utilizza un'immagine diversa in base al numero di
                giocatori. Segui le istruzioni sotto per completare il
                tabellone.
              </p>

              {/* Ciclo per ogni step (0, 1, 2, 3) */}
              {[0, 1, 2, 3].map((step) => (
                <div key={step} className="ion-margin-vertical">
                  <h4>Step {step}</h4>
                  <div className="ion-justify-content-center">
                    <img
                      alt={`Tabellone - Step ${step}`}
                      src={getImagePath(step)}
                      style={{
                        width: "100%",
                        height: "auto",
                        margin: "10px 0",
                        borderRadius: "8px",
                      }}
                    />
                  </div>
                  {step === 0 ? (
                    <p>
                      Prendete le tessere Tana e collocatele sugli spazi
                      indicati in figura.
                    </p>
                  ) : (
                    <p>
                      Prendete le tessere luogo di livello {step} e mescolatele.
                      Distribuite le tessere coperte a tutti i giocatori. Ogni
                      giocatore ne sceglie una e la piazza sugli spazi indicati
                      in figura e adiacente ad una sua tessera luogo già
                      presente. Dopodiché passate le tessere luogo restanti al
                      giocatore alla vostra sinistra e ripetete il procedimento
                      finché non avete piazziato tutte le tessere luogo.
                    </p>
                  )}
                </div>
              ))}

              <p>
                Qualora due giocatori vogliono piazzare contemporaneamente la
                propria tessera sulla stessa casella, ha la priorità il
                giocatore la cui tessera presenta il numero maggiore.
              </p>
              <p>
                Una volta completati tutti gli step, rivelate le tessere luogo
                sul tabellone e sarete pronti per iniziare la partita.
              </p>
            </div>
          </div>
        </IonCardContent>
      </IonCard>

      <IonCard className="ion-padding small-content-with-background">
        <IonButton
          expand="block"
          size="large"
          onClick={() => {
            history.push("/intro");
          }}
        >
          {t("start")}
        </IonButton>
      </IonCard>
    </div>
  );
};

export default Tabletop;
