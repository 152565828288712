import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useContext } from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Story from "../components/Story";
import { AdventuresContext, getAdventureById } from "../logic/adventures";
import { EventsContext } from "../logic/events";
import { getStoryById, getStoryIdFromTileId } from "../logic/stories";

const EpiloguePage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { currentAdventureId } = useContext(AdventuresContext);
  const { eventsIds } = useContext(EventsContext);

  const epilogue = getStoryById(
    getStoryIdFromTileId(currentAdventureId, 1000, eventsIds)
  );

  // Return blank page if needed arguments are null
  if (!currentAdventureId || !epilogue) return <IonPage></IonPage>;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="dragon-skin-background">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{getAdventureById(currentAdventureId).name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="body-background">
        <Story story={epilogue} />
      </IonContent>
      <IonFooter className="ion-padding ion-text-center dragon-skin-background"></IonFooter>
    </IonPage>
  );
};

export default EpiloguePage;
